<script setup>
import { useLayoutStore } from '~/stores/layout.store';
import { useUserStore } from '~/stores/user.store';

const userStore = useUserStore();
const user = computed(() => userStore.userGetter());



const layoutStore = useLayoutStore();
const config = useRuntimeConfig();
const accountUrlComputed = config.public.accountUrl;

const menus = ref([]);

const menusInHeaders = await layoutStore.fetchMenusInHeader('website')
menus.value = menusInHeaders;


</script>
<template>
	<div class="">
		<ul class="flex items-center">
			<template v-for="(menu, idx) in menus" v-bind:key="idx">
				<template v-if="!menu.only_for_logged_in_users || user!=undefined">
					<template v-if="menu.children && menu.children.length > 0">
						<li class="text-neutral-400 hover:text-indigo-900 lg:px-3 sm:px-1.5 md:px-2 pt-2">
							<w-drop-down-with-hover>
							<template #default class="cursor-pointer">
								<NuxtLink class="cursor-pointer" v-if="menu.url !== 'javascript:void(0)'" :to="localePath(`/${menu.url}`)">{{ menu.name }}</NuxtLink>
								<NuxtLink class="cursor-pointer" v-else >{{ menu.name }}</NuxtLink>
							</template>

								<template #item>
									
									<template v-if="menu.children.length > 0" >
										
										<li v-for="(subChild, sc) in menu.children" :key="sc" class="w-full text-left  hover:bg-neutral-100 pl-2 rounded-md py-2">
											<NuxtLink :to="localePath(`/${subChild.url}`)" class="block" >{{ subChild.name }}</NuxtLink>
										</li>
									</template>
									<template v-else>
										<li class="w-full text-left">
											<NuxtLink :to="localePath(item.url.startsWith('/') ? item.url : `/${item.url}`)" class="block">{{ item.name }}</NuxtLink>
										</li>
									</template>
								</template>
							</w-drop-down-with-hover> 
						</li>
					</template>
					<template v-else>
						<li class="text-neutral-400 hover:text-indigo-900 lg:px-3 sm:px-1.5 md:px-2 py-2 item">
							<NuxtLink :to="localePath(`/${menu.url}`)">{{ menu.name }} </NuxtLink>
						</li>
					</template>
				</template>
			</template>
		</ul>
	</div>
	<NuxtLink :to="accountUrlComputed"
		class="inline-flex items-center rounded-md bg-indigo-900 sm:px-0.5 md:px-2 py-2 text-sm  text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{{ $t('client.let-sell-rent') }}</NuxtLink>
</template>
